<template>
  <div>
    <md-tabs @md-changed="listenerChangeTab">
      <md-tab id="tab-form" md-label="Establecimientos">
        <md-table>
          <md-table-toolbar>
            <h1 class="md-title">Establecimientos
              <p class="md-caption">Seleccinar un establecimiento para continuar</p>
            </h1>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to('/')">
                <md-icon>keyboard_backspace</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="big" md-direction="bottom-end">
              <md-button class="md-icon-button" @click="go_to(`/form_store`)">
                <md-icon>add</md-icon>
              </md-button>
            </md-menu>
            <md-menu md-size="auto">
              <md-button @click.prevent="showDialog=true">
                <md-icon>filter_list</md-icon>
              </md-button>
            </md-menu>
          </md-table-toolbar>
          <md-table-row>
           <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
            <md-table-head>Nombre</md-table-head>
            <md-table-head>Teléfono</md-table-head>
            <md-table-head class="md-xsmall-hide md-small-hide">Correo electrónico</md-table-head>
            <md-table-head>Dirección</md-table-head>
            <md-table-head>Elegir</md-table-head>
            <md-table-head>Editar</md-table-head>
          </md-table-row>
          <md-table-row v-for="(item, index) in list_data.mdData" :key="index">
            <md-table-cell class="md-xsmall-hide md-small-hide">{{ index + 1 }}</md-table-cell>
            <md-table-cell>{{ item.name }}</md-table-cell>
            <md-table-cell>{{ item.phone }}</md-table-cell>
            <md-table-cell class="md-xsmall-hide md-small-hide">{{ item.email }}</md-table-cell>
            <md-table-cell>{{ item.address }}</md-table-cell>
            <md-table-cell >
              <md-button class="md-icon-button md-primary" @click="set_store(item)">
                <md-icon class="md-size-2x" >done</md-icon>
              </md-button>
            </md-table-cell>
            <md-table-cell >
              <md-button class="md-icon-button" @click="go_to(`/form_store/${item.id}`)">
                <md-icon class="md-size-1x" >edit</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-empty-state
        v-if="list_data.mdData.length === 0"
          md-icon="free_breakfast"
          md-label="Crear una venta"
          md-description="Aún no hay establecimientos registrados, crea el primero.">
          <md-button md-theme="blue-button" class="md-accent md-raised"
          @click="go_to(`/form_store`)">Crear un establecimiento</md-button>
        </md-empty-state>
      </md-tab>
      <md-tab tittle="Configuración" md-label="Config" id="tab-config">
        <config ></config>
      </md-tab>
      <md-tab title="Carga en lotes" md-label="Lotes" id="tab-lotes">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <p class="md-caption">Carga de documentos en masa, es posible
            cargar un año de movimientos.</p>
            <p class="md-caption">Selecciona un archivo excel con el formato correcto.</p>
            <uploadv2
              url = "upload_batch_documenst_v2/"
              prop_id="lotes">
            </uploadv2>
          </div>
        </div>
      </md-tab>
      <md-tab title="." md-label="." id="tab-2">
      </md-tab>
      <md-tab title="Carga historico en lotes" md-label="Lotes historico" id="tab-lotes-historico">
        <div class="md-layout">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <p class="md-caption">Carga de documentos en masa, es posible
            cargar un año de movimientos.</p>
            <p class="md-caption">Selecciona un archivo excel con el formato correcto.</p>
            <upload
              url = "upload_batch_documenst_custom/">
            </upload>
          </div>
        </div>
      </md-tab>
      <md-tab title="Cuentas bancarias" md-label="Cuentas" id="tab-cuentas">
        <div class="md-layout">
          <div class="md-layout-item md-size-60 md-small-size-100">
              <md-card-header>
                <div class="md-title">Cuntas bancarias</div>
              </md-card-header>
              <md-card-content>
                <md-table  >
                  <md-table-row>
                    <md-table-head class="md-xsmall-hide md-small-hide">#</md-table-head>
                    <md-table-head>Nombres</md-table-head>
                    <md-table-head >Número</md-table-head>
                    <md-table-head class="md-xsmall-hide md-small-hide">Tipo</md-table-head>
                    <md-table-head >Banco</md-table-head>
                    <md-table-head >Editar</md-table-head>
                  </md-table-row>
                  <md-table-row v-for="(item, index) in list_account" :key="item.id">
                    <md-table-cell class="md-xsmall-hide md-small-hide" >
                    {{index + 1}}</md-table-cell>
                    <md-table-cell  md-sort-by="name">{{ item.name }}</md-table-cell>
                    <md-table-cell>{{ item.account_number }}</md-table-cell>
                    <md-table-cell class="md-xsmall-hide md-small-hide">
                    {{ item.type_account_display }}</md-table-cell>
                    <md-table-cell>{{ item.bank_name }}</md-table-cell>
                    <md-table-cell >
                      <md-button class="md-icon-button" @click="to_edit(item, index)">
                        <md-icon class="md-size-1x" >edit</md-icon>
                      </md-button>
                    </md-table-cell>
                </md-table-row>
                </md-table>
                <md-progress-bar md-mode="indeterminate" v-if="sending" />
              </md-card-content>
          </div>
          <div class="md-layout-item md-size-40 md-small-size-100">
            <md-card >
              <form novalidate @submit.prevent="validateAccount">
                <md-card-header>
                  <md-card-header-text>
                    <div class="md-title" v-if="account_obj.id">Editar cuenta bancaria</div>
                    <div class="md-title" v-if="!account_obj.id">Nueva cuenta bancaria</div>
                  </md-card-header-text>
                  <md-menu md-size="big" title="Nuevo" md-direction="bottom-end">
                    <md-button class="md-icon-button" @click="reset_account_form()">
                      <md-icon>add</md-icon>
                    </md-button>
                  </md-menu>
                </md-card-header>
                <md-card-content>
                  <div class="md-layout md-gutter">
                    <div class="md-layout-item md-small-size-100">
                      <md-field :class="getValidationClassAccount('name')">
                        <label for="name">Nombre cuenta bancaria</label>
                        <md-input ref="name_account" v-model="account_obj.name"
                        :disabled="sending" />
                        <span class="md-error"
                        v-if="!$v.account_obj.name.required">
                      EL nombre de la cuenta bancaria es requerida</span>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout md-gutter">
                    <div class="md-layout-item md-size-50">
                      <md-field :class="getValidationClassAccount('account_number')">
                        <label for="account_number">Número</label>
                        <md-input
                        v-model="account_obj.account_number" :disabled="sending" />
                        <span class="md-error"
                        v-if="!$v.account_obj.account_number.required">
                      El numero de cuenta es requerido</span>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-50">
                      <md-field :class="getValidationClassAccount('type_account')">
                        <label for="type_account">Tipo cuenta bancaria</label>
                      <md-select v-model="account_obj.type_account"
                        :disabled="sending"
                      >
                      <md-option :value="item.value"
                        v-for="(item, key) in choosen_type_account"
                        :key="key">{{item.text}}</md-option>
                      </md-select>
                      <span class="md-error"
                        v-if="!$v.account_obj.type_account.required">
                        El tipo de la cuenta requerido</span>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout md-gutter">
                    <div class="md-layout-item md-small-size-100">
                      <md-field :class="getValidationClassAccount('saldo_inicial')">
                        <label for="saldo_inicial">Saldo inicial</label>
                        <md-input v-model="account_obj.saldo_inicial"
                        type="number" :disabled="sending" />
                        <span class="md-error"
                        v-if="!$v.account_obj.saldo_inicial.required">
                      El saldo_inicial es requerido</span>
                      </md-field>
                    </div>
                  </div>
                  <div class="md-layout md-gutter">
                    <div class="md-layout-item md-small-size-100">
                      <md-autocomplete autocomplete="off"
                        :class="getValidationClassAccount('bank')"
                        id="bank_name" class="autocomplete"
                        :disabled="sending"
                        v-model="account_obj.bank_name"
                        md-input-name="bank"
                        :md-options="list_banks"
                        @md-selected="set_bank"
                        @md-changed="get_list_banks" >
                      <label for="bank_name">Banco {{account_obj.bank_name}}</label>
                      <template
                        slot="md-autocomplete-item" slot-scope="{ item, term }">
                        <md-highlight-text :md-term="term">
                          {{ item.name }}</md-highlight-text>
                      </template>
                      <span class="md-error"
                        v-if="!$v.account_obj.bank.required">
                        La cuenta contable es requerida</span>
                      </md-autocomplete>
                    </div>
                  </div>
                </md-card-content>
                <md-progress-bar md-mode="indeterminate" v-if="sending" />
                <md-card-actions>
                  <md-button
                  type="submit" class="md-primary" :disabled="sending">Guardar cambios</md-button>
                </md-card-actions>
              </form>
            </md-card>
          </div>
          <md-snackbar
            :md-active.sync="alert">{{ message }}</md-snackbar>
          <br>
          <br>
        </div>
      </md-tab>
    </md-tabs>
    <md-progress-bar md-mode="indeterminate" v-if="sending_request" />
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Filtros</md-dialog-title>
      <md-card-content>
        <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="name">Nombre</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.name" type="text" :disabled="sending_request" />
            </md-field>
          </div>
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label for="email">Correo</label>
                <md-input @keyup.enter="list(1, rowsPerPage)"
                v-model="filters.email" type="text" :disabled="sending_request" />
              </md-field>
            </div>
          </div>
          <div class="md-layout md-gutter">
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="nit">NIT</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.nit" type="text" :disabled="sending_request" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100">
            <md-field >
              <label for="phone">Teléfono</label>
              <md-input @keyup.enter="list(1, rowsPerPage)"
              v-model="filters.phone" type="text" :disabled="sending_request" />
            </md-field>
          </div>
        </div>
      </md-card-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">Cerrar</md-button>
        <md-button class="md-primary" @click="reset_filters">Limpiar filtro</md-button>
        <md-button class="md-primary" @click="list(1, rowsPerPage)">Filtrar</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import moment from 'moment';
/* eslint-disable */
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
} from 'vuelidate/lib/validators'
export default {
  name: 'Stores',
  created(){
    let current_filters = this.get_data_storage(this.name_storage_filter)
    if(current_filters){
      this.filters = current_filters
    }
    this.list(1, this.rowsPerPage)
    document.addEventListener('keyup', e => {
      if (e.key === 'f4' || e.keyCode === 115) {
        this.showDialog = true
      }
    })
  },
  mixins: [validationMixin],
  components: {
    config: () => import('../views/ListConfigCuentas.vue'),
    upload: () => import('../components/UploadFile.vue'),
    uploadv2: () => import('../components/UploadFileV2.vue'),
  },
  data() {
    return {
      sending_request: false,
      message: null,
      alert: false,
      filters: {},
      current_tab: null,
      list_data: {
        mdCount: null,
        mdPage: null,
        mdData: []
      },
      book: {
        book_purchase: null,
        book_sale: null,
        book_diary: null
      },
      account_obj: {
        bank: null,
        name: null,
        type_account: null,
        saldo_inicial: null,
        account_number: null,
      },
      choosen_type_account: [
        {value:'S', text: 'AHORROS'},
        {value:'M', text: 'MONETARIA'},
        {value:'C', text: 'CHEQUES'},
      ],
      list_account: [],
      list_banks: [],
      url_banks: 'banks/',
      url_account_bank: 'account_bank/',
      sending: false,
      showDialog: false,
      show_list: true,
      rowsPerPage: 10,
      url: 'stores/',
      url_verify: 'verify/',
      name_storage_filter: 'filter_stores',
      sending_request: false,
    };
  },
  validations: {
    account_obj: {
      name: {
        required,
      },
      type_account: {
        required,
      },
      saldo_inicial: {
        required,
      },
      account_number: {
        required,
      },
      bank: {
        required,
      },
    },
  },
  methods: {
    go_to(url) {
      this.$router.push(url);
    },
    set_store(obj) {
      this.$store.dispatch('set_store', { storeObj: obj });
      this.verify_book()
      this.$swal.fire('Seleccinado', 'El establecimiento ha sido seleccionado, puede hacer una acción para continuar', 'success');
    },
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    remove_storage(key) {
      localStorage.removeItem([key]);
    },
    get_data_storage(name) {
      return JSON.parse(localStorage.getItem(name));
    },
    reset_filters: function () {
      this.filters = {}
      this.remove_storage(this.name_storage_filter)
      this.list()
    },
    clearFormAccount () {
      this.$v.account_obj.$reset()
    },
    listenerChangeTab(tab_id){
      this.current_tab = tab_id

      if (tab_id==='tab-cuentas' && this.list_account.length === 0){
        this.get_list_account()
      }
    },
    set_bank(obj) {  
      this.account_obj.bank_name = obj.name;
      this.account_obj.bank = obj.id;
    },
    saveOrUpdateAccount () {
      if (!this.account_obj.name) {
        this.$swal.fire('Error!', 'El nombre requerido.', 'error')
        return false
      }
      const payload = this.account_obj
      const self = this
      this.sending = true
      if (this.account_obj.id) {
        this.putRequest(this.url_account_bank, payload).then(function (data) {
          self.message = `La cuenta bancaria: ${self.account_obj.name} ha sido actualizada.`
          self.alert = true
          self.sending = false
          self.account_obj = data.data
          self.clearFormAccount()
        }).catch(function (error) {
          self.sending = false
          if (typeof (error.response.data) === 'object') {
            const result = error.response.data
            Object.entries(result).forEach(
              ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
            )
            return false
          }
          self.$swal.fire('Error!', error.response.data, 'error')
        })
        return
      }
      payload.company = this.$store.state.company_obj.id
      if (!payload.company) {
        self.$swal.fire('Error!', 'El contribuyente es requerido', 'error')
        self.sending = false
        return false;
      }
      this.postRequest(this.url_account_bank, payload).then(function (data) {
        self.message = `La cuenta bancaria: ${self.account_obj.name} ha sido agregada.`
        self.alert = true
        self.sending = false
        self.account_obj = data.data
        self.list_account.unshift(data.data)
        self.clearFormAccount()
      }).catch(function (error) {
        self.sending = false
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    get_list_banks(searchTerm) {
      const payload = {};
      if (searchTerm) {
        payload.toke = searchTerm
        payload.filter = "True"
        payload.accountSearch = "1"
      }
      filter: "True"
      const self = this;
      this.sendRequest_with_url(this.url_banks, payload ).then((data) => {
        self.list_banks = data.data;
      }).catch((error) => {
        console.log(error);
        this.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    getValidationClassAccount (fieldName) {
      const field = this.$v.account_obj[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validateAccount () {
      this.$v.account_obj.$touch()
      if (!this.$v.account_obj.$invalid) {
        this.saveOrUpdateAccount()
        return 
      }
      // this.clearFormAccount()
    },
    reset_account_form(){
      this.account_obj = {
        bank: null,
        name: null,
        type_account: null,
        saldo_inicial: null,
        account_number: null,
      }
      this.$refs.name_account.$el.focus()
    },
    get_list_account() {
      const payload = {};
      const self = this;
      payload.company = this.$store.state.company_obj.id


      if (!payload.company) {
        self.message = 'No se puede obtener cuentas bancarias aún.'
        self.alert = true
      }
      this.sendRequest_with_url(this.url_account_bank, payload).then((data) => {
        self.list_account = data.data;
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    to_edit(instance, index) {
      this.account_obj = instance
      this.$refs.name_account.$el.focus()
    },
    list(page, pageSize, sort, sortOrder) {
      let payload = {};
      const self = this;
      payload.company = this.$store.state.company_obj.id
      payload.paginate = "True"
      payload.page = page
      payload.per_page = pageSize
      if (this.sending_request) return false
      this.sending_request = true

      if (Object.keys(this.filters).length > 0){
        Object.assign(payload, this.filters)
      }
      this.sendRequest_with_url(this.url, payload).then((data) => {
        self.rowsPerPage = 50
        self.list_data = {
          mdCount: data.data.count,
          mdPage: page,
          mdData: data.data.results
        }
        this.sending_request = false
        this.save_storage(this.name_storage_filter, this.filters,)
        this.showDialog = false
      }).catch((error) => {
        this.sending_request = false
        this.handlerError(error)
      });
      return null;
    },
    verify_book(){
      localStorage.removeItem('book_purchase');
      localStorage.removeItem('book_sale');
      localStorage.removeItem('book_diary');
      const self = this
      const payload = {}
      payload.company = this.$store.state.company_obj.id
      payload.store = this.$store.state.store_obj.id
      this.getBook(this.url_verify, payload).then(function (data) {
        self.book = data.data

        if (!self.book.book_purchase && !self.book.book_sale && self.book.book_diary){
          //Si no tiene algun libro
          self.save_storage('book_diary', self.book.book_diary) // se guarda en storage de nuevo el libro diario
          self.$swal.fire('Error!', 'Libros no encontrados, vamos rerireccionar', 'error')
          setTimeout(() => {
            self.$router.push('/books')
            self.$swal.close()
          }, 3000)
          return
        }

        //Guardar en storage
        if (self.book.book_purchase){
          self.save_storage('book_purchase', self.book.book_purchase)
        }
        if (self.book.book_sale){
          self.save_storage('book_sale', self.book.book_sale)
        }
        if (self.book.book_diary ){
          self.save_storage('book_diary', self.book.book_diary)
        }
        //Redireccionar
        if (!self.book.book_purchase || !self.book.book_diary || !self.book.book_sale ){
          setTimeout(() => {
            self.$swal.close()
            self.$swal.fire('Error!', 'Uno o más libros no encontrados, vamos rerireccionar', 'error')
          }, 3000)
          setTimeout(() => {
            self.$router.push('/books')
            self.$swal.close()
          }, 5000)
          return
        }
        
      }).catch(function (error) {
        if (typeof (error.response.data) === 'object') {
          const result = error.response.data
          Object.entries(result).forEach(
            ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
          )
          return false
        }
        self.$swal.fire('Error!', error.response.data, 'error')
      })
    },
    async sendRequest(payload) {
      const result = await this.$http.get(this.url,{params:  payload});
      return result;
    },
    async sendRequest_with_url(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    async putRequest (url, payload) {
      const result = await this.$http.put(`${url}${payload.id}/`, payload)
      return result
    },
    async postRequest (url, payload) {
      const result = await this.$http.post(url, payload)
      return result
    },
    async getBook(url, payload) {
      const result = await this.$http.get(url,{params:  payload});
      return result;
    },
    handlerError: function (error) {
      if (typeof (error.response.data) === 'object') {
        const result = error.response.data
        Object.entries(result).forEach(
          ([key, value]) => this.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      this.$swal.fire('Error!', error.response.data, 'error')
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'COMPRA';
      return 'VENTA';
    },
    to_date(value) {
      if (!value ) return '-----'
      return moment(value, 'YYYY-MM-DD', 'es').format('ll')
    },
  },
};

</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
  .avatar img {
    max-width: 30px;
  }
</style>
<style lang="css" scoped>
  .on-over {
    cursor: pointer;
  }
  .fa-refresh {
      -webkit-animation: infinite-spinning 5s normal linear infinite;
      animation: infinite-spinning 5s normal linear infinite;
  }
  .null{
    text-decoration: line-through;
  }
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  @keyframes infinite-spinning {
    0% {
       -webkit-transform: rotate3d(0, 0, 1, 0deg);
       transform: rotate3d(0, 0, 1, 0deg);
     }
     25% {
       -webkit-transform: rotate3d(0, 0, 1, 90deg);
       transform: rotate3d(0, 0, 1, 90deg);
     }
     50% {
       -webkit-transform: rotate3d(0, 0, 1, 180deg);
       transform: rotate3d(0, 0, 1, 180deg);
     }
     75% {
       -webkit-transform: rotate3d(0, 0, 1, 270deg);
       transform: rotate3d(0, 0, 1, 270deg);
     }
     100% {
       -webkit-transform: rotate3d(0, 0, 1, 360deg);
       transform: rotate3d(0, 0, 1, 360deg);
     }
  }
  .bounce-enter-active {
    animation: bounce-in .5s;
  }
  .bounce-leave-active {
    animation: bounce-in .5s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
